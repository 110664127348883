<template>
  <div class="container">
    <BaseLoad v-if="loading" />
    <div class="con" v-else>
      <!-- 购买详情 -->
      <div class="flex item">
        <div class="item-l">
          <img :src="preSrc + info.cover" alt="" />
        </div>
        <div class="item-r">
          <h3 class="ellipsis" :title="info.name">{{ info.name }}</h3>
          <p class="desc">{{ info.desc }}</p>
          <p class="price">￥{{ info.order_price }}</p>
        </div>
      </div>
      <!-- 订单信息 -->
      <div class="order-con">
        <h3>订单信息</h3>
        <div class="order-info flex">
          <p>订单编号：{{ info.number }}</p>
          <p>创建时间：{{ info.time }}</p>
          <p>
            应付金额: <span>￥{{ info.order_price }}</span>
          </p>
        </div>
        <div class="pay-qr_code">
          <img :src="preSrc + info.qr_code" alt="" />
        </div>
        <p class="pay-tip">微信扫描二维码付款</p>
      </div>
    </div>
  </div>
</template>
<script>
import BaseEmpty from "@/components/empty/BaseEmpty.vue";
import BaseLoad from "@/components/loading/BaseLoading.vue";
import ListLoad from "@/components/loading/ListLoading.vue";
export default {
  components: {
    BaseEmpty,
    BaseLoad,
    ListLoad,
  },
  data() {
    return {
      preSrc: config.src,
      info: {},
      loading: true,
      timber: null,
      type:'',//2课程1游戏
    };
  },
  created() {
    let _self = this,
      id = "";
    id = _self.$route.params.id;
    _self.type = _self.$route.params.type;
    _self.initData(id, _self.type);
  },
  methods: {
    // 初始化
    initData(id, type) {
      let _self = this;
      _self.axios
        .post(
          config.url.orderCreate,
          _self.$qs.stringify({ project_id: id, type: type })
        )
        .then((res) => {
          if (res.code == 200) {
            _self.info = res.data;
            _self.loading = false;
            let enterTamp = new Date().getTime() + 60 * 60 * 1000;
            _self.timber = setInterval(() => {
              let currentTamp = new Date().getTime()
              if(enterTamp -  currentTamp <= 0){
                _self.orderTimeout(res.data.number)
                return;
              }
              _self.handleOrderResult(res.data.number);
            }, 3000);
          } else if (res.code != 200 && res.code != 401 && res.code != 500) {
            _self.showTip("error", res.msg);
          }
        });
    },

    // 订单查询结果
    handleOrderResult(orderNum) {
      let _self = this;
      _self.axios
        .post(config.url.payResult, _self.$qs.stringify({ number: orderNum }))
        .then((res) => {
          if (res.code == 200) {
            if (res.data.status == 1) {
              if(_self.type == 1){
                _self.$router.replace("/profile/game");
                return;
              }
              _self.$router.replace("/profile/course");
            }
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
    },
    // 订单支付超时
    orderTimeout(orderNum) {
      let _self = this;
      _self.axios
        .post(config.url.orderTimeout, _self.$qs.stringify({ number: orderNum }))
        .then((res) => {
          if (res.code == 200) {
            _self.showTip("error", res.msg);
            _self.$router.replace("/profile/order");
            return;
          } 
          if(res.code == 500){
            _self.$router.replace("/profile/order");
            return;
          }
            _self.showTip("error", res.msg);
         
        });
    },
  },
  beforeDestroy() {
    let _self = this;
    clearInterval(_self.timber);
    _self.timber = null;
  },
};
</script>
<style lang="scss" scoped>
.container{
  background: #fff;
}
.con {
  padding-bottom: 80px;
  .item {
    background: #fff;
    box-sizing: border-box;
    margin: 60px auto;
    .item-l {
      flex-shrink: 0;
      flex-grow: 0;
      border-radius: $border-radius-main;
      overflow: hidden;
      img {
        width: 260px;
        height: 170px;
        object-fit: contain;
      }
    }
    .item-r {
      width: 940px;
      height: 170px;
      flex-shrink: 0;
      flex-grow: 0;
      padding: 10px 30px 10px;
      box-sizing: border-box;
      border-left: 0;
      border-radius: 0 10px 10px 0;
      display: flex;
      flex-direction: column;
      h3 {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .desc {
        font-size: 18px;
        color: $font-color-51;
        line-height: 30px;
        height: 60px;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; //行数
        -webkit-box-orient: vertical;
      }

      .price {
        font-size: 30px;
        color: $color-main;
        font-weight: bold;
      }
      .del-price {
        font-size: 18px;
        color: $font-color-9f;
      }
      .itme-r-b {
        background: $font-color-9f;
      }

      .icon-duihao {
        margin-right: 5px;
      }
    }
  }
  .icon-shoucang,
  .icon-star-filled {
    color: #f9e457;
    margin-right: 7px;
  }
  .icon-shoucang,
  .icon-star-filled {
    color: #f9e457;
    margin-right: 7px;
  }
  .favor i,
  .work img,
  .btn:hover,
  .list:hover {
    cursor: pointer;
  }
  .favor-work {
    color: $font-color-9f;
    justify-content: flex-start;
  }
  .slash {
    margin: 0 15px;
  }
  .favor {
    margin-top: -3px;
  }
  .work img {
    margin-right: 10px;
  }
  .favor i {
    margin-right: 6px;
  }
  .workplace {
    display: inline-block;
    width: 18px;
    height: 18px;
    object-fit: contain;
  }
  .order-con {
    h3 {
      font-size: 30px;
      margin-bottom: 20px;
    }
    .order-info {
      justify-content: flex-start;
      margin-bottom: 80px;
      p {
        font-size: 20px;
        margin-right: 70px;
        color: $font-color-51;
        span {
          color: $color-main;
        }
      }
    }
    .pay-qr_code {
      width: 300px;
      height: 300px;
      margin: 0 auto;
      border-radius: $border-radius-main;
      border: 1px solid #dfdfdf;
      padding: 10px;
      box-sizing: border-box;
      img {
        width: 100%;
      }
    }
    .pay-tip {
      font-size: 20px;
      color: $font-color-81;
      margin-top: 20px;
      text-align: center;
    }
  }
}
</style>
